import { createStore } from 'vuex';
import config from '../config';

export default createStore({
  state: {
    currentLocale: localStorage.getItem(`${config.appKey}_locale`) || config.defaultLocale,
  },
  getters: {
  },
  mutations: {
    currentLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem(`${config.appKey}_locale`, locale);
    },
  },
  actions: {
    changeLocale(context, locale) {
      context.commit('currentLocale', locale);
    },
  },
  modules: {
  },
});
