import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import OniverseView from '../views/OniverseView.vue';
import SphereBoardView from '../views/SphereBoardView.vue';
import TeamView from '../views/TeamView.vue';

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/oniverse',
    name: 'oniverse',
    component: OniverseView,
  },
  {
    path: '/sphere-board',
    name: 'sphere-board',
    component: SphereBoardView,
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
