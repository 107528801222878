<template>
  <div
    class="bubble"
    :style="getStyles()"
  >
    <div class="content">
      <div v-if="content.length && content[0] != ''">
        <div v-for="(contentItem, index) in content" :key="index" class="label">
          {{ contentItem }}
        </div>
      </div>
      <img v-else :src="questionMark" alt="???" class="questionMark">
    </div>
  </div>
</template>

<script>
import questionMark from '@/assets/images/question-mark.svg';

export default {
  name: 'Bubble',
  data() {
    return {
      questionMark,
    };
  },
  props: {
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 250,
    },
    content: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: 'right',
    },
    show: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
    },
  },
  methods: {
    getStyles() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        ...this.styles,
      };
    },
  },
};
</script>
