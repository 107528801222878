<template>
  <img
    class="home-bg-image mobile"
    alt="Mobile background image"
    src="../assets/images/home-bg-mobile.png"
  />
  <div class="home">
    <img
      v-if="mobileCharacter"
      class="main-character-image mobile"
      alt="Character image"
      :src="mobileCharacter.characterImage"
    />
    <div class="countdown-wrapper mobile">
      <div class="countdown-label" v-html="$t('common.labels.countdown')" />
      <vue-countdown
        class="countdown-timer"
        :time="characterChangeTimer"
        v-slot="{ hours, minutes, seconds }"
      >
        {{ String(hours).length === 1 ? `0${hours}` : hours }}:{{
          String(minutes).length === 1 ? `0${minutes}` : minutes
        }}:{{ String(seconds).length === 1 ? `0${seconds}` : seconds }}
      </vue-countdown>
    </div>
    <div class="main-image-overlay desktop" ref="mainImageOverlay">
      <template
        v-for="(characterData, index) in desktopCharacterItems"
        :key="index"
      >
        <img
          :ref="'characterImage' + characterData.name"
          :src="characterData.characterImage"
          class="overlay-character-image image-source"
          :class="{ disabled: !characterData.clickableAreaImage }"
          alt="Character image"
          :style="{
            top: `${characterData.coords.top}%`,
            left: `${characterData.coords.left}%`,
            height: `${characterData.height || 100}%`,
            zIndex: characterData.zIndex,
          }"
        />
        <img
          :ref="'characterImageOutline' + characterData.name"
          :src="characterData.characterImage"
          class="overlay-character-image outline-source"
          alt="Character image outline"
          :style="{
            top: `${characterData.coords.top}%`,
            left: `${characterData.coords.left}%`,
            height: `${characterData.height || 100}%`,
          }"
        />
      </template>
      <div
        class="character-clickable-area"
        @mousemove="onClickableAreaMouseMove"
        @mouseout="onClickableAreaMouseOut"
      >
        <img
          v-for="(characterData, index) in desktopCharacterItems.filter(
            (char) => char.clickableAreaImage
          )"
          :key="index"
          :ref="'characterClickableArea' + characterData.name"
          :data-charactername="characterData.name"
          v-svg-inline
          :src="characterData.clickableAreaImage"
          alt="Character clickable area"
          :style="{
            top: `${characterData.coords.top}%`,
            left: `${characterData.coords.left}%`,
            height: `${characterData.height || 100}%`,
          }"
        />
      </div>
      <Bubble
        :show="!!bubbleContent && !!bubbleContent.length"
        :content="bubbleContent"
        :styles="getBubbleStyle()"
        :position="getBubblePosition()"
      />
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import * as _ from 'lodash';
import desktopCharacters from '../fixtures/homepage-characters-desktop';
import mobileCharacters from '../fixtures/homepage-characters-mobile';
import config from '../config';
import Bubble from '../components/Bubble.vue';

const STORAGE_CHARACTER_INDEX_KEY = `${config.appKey}_home_character_index`;
const STORAGE_CHARACTER_SELECTION_DATE_KEY = `${config.appKey}_home_character_selected_date`;

export default {
  name: 'HomeView',
  components: {
    Bubble,
  },
  data() {
    const now = new Date();
    const currentHour = moment().hour();
    const currentMinute = moment().minute();

    /*
      Used for countdown widget (time left before the selectino of the next character
      on the mobile devices)
    */
    let nextCharacterChangeTime;

    if (currentHour === 0 || (currentHour === 1 && currentMinute === 0)) {
      nextCharacterChangeTime = moment().hour(1).minute(0).toDate();
    } else {
      nextCharacterChangeTime = moment()
        .add(1, 'day')
        .hour(1)
        .minute(0)
        .toDate();
    }

    return {
      mobileCharacter: null,
      desktopCharacterItems: desktopCharacters,
      mobileCharacterItems: mobileCharacters,
      bubbleContent: [],
      currentCharacterData: null,
      characterChangeTimer: nextCharacterChangeTime - now,
    };
  },
  mounted() {
    this.chooseMobileCharacterOfDay();
  },
  updated() {
    this.chooseMobileCharacterOfDay();
  },
  methods: {
    chooseMobileCharacterOfDay() {
      const characterIndex = +localStorage.getItem(STORAGE_CHARACTER_INDEX_KEY);

      const characterImageSelectedDate = localStorage.getItem(
        STORAGE_CHARACTER_SELECTION_DATE_KEY,
      );

      const selectionDate = moment(+characterImageSelectedDate);

      /*
        If the local storage contains the data about the selected character and the selection
        was done today after 1:00, then the local storage selection info is reused
      */
      if (
        characterIndex > -1
        && characterIndex < this.mobileCharacterItems.length
        && characterImageSelectedDate
        && selectionDate.isValid()
        && selectionDate.isAfter(moment().startOf('day').hour(1))
      ) {
        this.mobileCharacter = this.mobileCharacterItems[characterIndex];
        return;
      }

      // The character is randomly selected from the list of available characters
      const randomCharacter = this.mobileCharacterItems[
        Math.floor(Math.random() * this.mobileCharacterItems.length)
      ];

      this.mobileCharacter = randomCharacter;

      // The selected character and selection time are saved in the local storage
      localStorage.setItem(
        STORAGE_CHARACTER_INDEX_KEY,
        this.mobileCharacterItems.indexOf(randomCharacter),
      );
      localStorage.setItem(
        STORAGE_CHARACTER_SELECTION_DATE_KEY,
        moment().valueOf(),
      );
    },
    /*
      Handling of 'mouseover' event for the clickable area - adding the event
      listeners to the svg path tags
     */
    onClickableAreaMouseMove(e) {
      if (e.target.tagName === 'path') {
        this.onCharacterMouseMove(
          e.target.closest('svg').getAttribute('data-charactername'),
        );
      }
    },
    onClickableAreaMouseOut(e) {
      if (e.target.tagName === 'path') {
        this.onCharacterMouseOut(
          e.target.closest('svg').getAttribute('data-charactername'),
        );
      }
    },
    /*
      Handling of 'mousemove' event for the character images - adding the grayscale filter
      to the main canvas image and inactive character images
    */
    onCharacterMouseMove(name) {
      this.$refs.mainImageOverlay.classList.add('gray');
      let targetCharacterElement = this.$refs[`characterImage${name}`];
      if (_.isArray(targetCharacterElement)) {
        [targetCharacterElement] = targetCharacterElement;
      }

      if (targetCharacterElement) {
        targetCharacterElement.classList.add('active');
      }

      // eslint-disable-next-line prefer-destructuring
      this.currentCharacterData = this.desktopCharacterItems.find(
        (character) => character.name === name,
      );
      if (
        this.currentCharacterData
        && this.currentCharacterData.bubble
        && this.currentCharacterData.bubble.content
      ) {
        this.bubbleContent = this.currentCharacterData.bubble.content;
      }
    },
    // Removing any filters from the main canvas image and character images for the 'mouseout' event
    onCharacterMouseOut(name) {
      // Make the bubble disappear
      this.bubbleContent = [];
      this.currentCharacterData = null;

      if (this.currentCharacterData === null) {
        this.$refs.mainImageOverlay.classList.remove('gray');
      }
      let targetCharacterElement = this.$refs[`characterImage${name}`];
      if (_.isArray(targetCharacterElement)) {
        [targetCharacterElement] = targetCharacterElement;
      }

      if (
        targetCharacterElement
        && (this.currentCharacterData === null
          || this.currentCharacterData.name !== name)
      ) {
        targetCharacterElement.classList.remove('active');
      }
    },
    /*
      Get the style object (left/top/width/height) for the bubble popover
      for particular character image rendered on desktop devices
    */
    getBubbleStyle() {
      if (
        !this.currentCharacterData
        || !this.currentCharacterData.bubble
        || !this.currentCharacterData.bubble.content
        || !this.currentCharacterData.bubble.content.length
        || !this.currentCharacterData.bubble.coords
      ) {
        return {};
      }

      const toReturn = {
        position: 'absolute',
        zIndex: 9999,
        opacity: 1,
      };

      const position = this.getBubblePosition();

      /*
        The popover element offsets are calculated based on the positionign setting
        ('left'/'right') and the offset values from the character fixtures file
      */
      if (position === 'left') {
        toReturn.right = `${
          100 - this.currentCharacterData.bubble.coords.left
        }%`;
      } else {
        toReturn.left = `${this.currentCharacterData.bubble.coords.left}%`;
      }

      if (this.currentCharacterData.bubble.size) {
        toReturn.width = `${this.currentCharacterData.bubble.size}px`;
        toReturn.height = `${this.currentCharacterData.bubble.size}px`;
      }

      toReturn.top = `${this.currentCharacterData.bubble.coords.top}%`;

      return toReturn;
    },
    // Get the position ('left' or 'right' for the bubble popover)
    getBubblePosition() {
      if (
        !this.currentCharacterData
        || !this.currentCharacterData.bubble
        || !this.currentCharacterData.bubble.position
      ) {
        return 'left';
      }

      // By default we take the position value from the character fixtures file
      return this.currentCharacterData.bubble.position;
    },
  },
};
</script>
