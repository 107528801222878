<template>
  <div class="social-menu">
    <a v-for="(socialItem, index) in items" :key="index" :href="socialItem.url">
      <img
        :src="socialItem.icon"
        class="bright"
        :class="{ disabled: socialItem.disabled }"
        :alt="socialItem.title"
      />
      <img
        :src="socialItem.darkIcon"
        class="dark"
        :class="{ disabled: socialItem.disabled }"
        :alt="socialItem.title"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  name: 'SocialMenu',
};
</script>
