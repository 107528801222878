<template>
  <div class="team">
    <div class="gallery">
      <div
        v-for="(memberData, index) in teamMembers"
        class="gallery-item"
        :key="index"
      >
        <div class="member-image">
          <img
            :src="memberData.imgPath"
            class="member-avatar"
            alt="Member avatar"
          />
          <img
            :src="memberData.hoverImgPath"
            class="member-photo"
            alt="Member photo"
          />
        </div>
        <div class="member-info">
          <div class="member-details fw-bold">
            {{ memberData.name }} - {{ memberData.position }}
          </div>
          <div
            v-if="memberData.description"
            v-html="memberData.description"
            class="member-details"
          />
          <div v-if="memberData.hoverDescription" class="member-details hover-only">
            <div class="member-details hover-only fw-bold">
            {{ memberData.name }} - {{ memberData.position }}
            </div>
            <div
              class="member-details hover-only content"
              v-if="memberData.hoverDescription"
              v-html="memberData.hoverDescription"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="sponsors">
      <img :src="sponsorsImage" :alt="$t('team.img.sponsors')" class="desktop">
      <a href="https://twitter.com/Onisekai_world">
        <img :src="sponsorsImageHover" :alt="$t('team.img.sponsors')" class="desktop hover">
      </a>
      <img :src="sponsorsImageMobile" :alt="$t('team.img.sponsors')" class="mobile">
      <a href="https://twitter.com/Onisekai_world">
        <img :src="sponsorsImageMobileHover" :alt="$t('team.img.sponsors')" class="mobile hover">
      </a>
    </div>
  </div>
</template>

<script>

import sponsorsImage from '@/assets/images/TEAM_BRAND_BAR_HOME_TEAMPAGE.svg';
import sponsorsImageHover from '@/assets/images/TEAM_BRAND_BAR_HOME_TEAMPAGE_REDIRECT_TWITTER.svg';
import sponsorsImageMobile from '@/assets/images/BANNER_CORE_TEAM_BRANDS_PHONE.svg';
import sponsorsImageMobileHover from '@/assets/images/BANNER_CORE_TEAM_BRANDS_PHONE_HOVER.svg';
import teamMembers from '../fixtures/team';

export default {
  name: 'TeamView',
  data() {
    return {
      teamMembers,
      sponsorsImage,
      sponsorsImageHover,
      sponsorsImageMobile,
      sponsorsImageMobileHover,
    };
  },
};
</script>
