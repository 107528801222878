<template>
  <div class="footer">
    <div class="footer-row">
      <div class="left">
        <router-link to="/home">
          <img
            :src="currentLogoImage"
            class="logo"
            alt="Logo"
            @mouseover="currentLogoImage = darkLogoImage"
            @mouseout="currentLogoImage = logoImage"
            @focusin="currentLogoImage = darkLogoImage"
            @focusout="currentLogoImage = logoImage"
            @click="openHomePage"
          />
        </router-link>
        <div class="text" v-html="$t('common.footer.leftText')"></div>
      </div>
      <div class="right">
        <div class="column column-2">
          <p><a href="#" class="disabled">{{ $t("common.footer.legalOverview") }}</a></p>
          <p><a href="#" class="disabled">{{ $t("common.footer.privacyPolicy") }}</a></p>
          <p><a href="#" class="disabled">{{ $t("common.footer.termsAndConditions") }}</a></p>
          <p><a href="#" class="disabled">{{ $t("common.footer.pressInfo") }}</a></p>
        </div>
        <div class="column column-3">
          <p><a :href="config.instagramUrl">{{ $t("common.footer.instagram") }}</a></p>
          <p><a :href="config.discordUrl">{{ $t("common.footer.discord") }}</a></p>
          <p><a :href="config.twitterUrl">{{ $t("common.footer.twitter") }}</a></p>
          <p><a href="#" class="disabled">{{ $t("common.footer.youtube") }}</a></p>
          <p><a href="#" class="disabled">{{ $t("common.footer.tiktok") }}</a></p>
        </div>
      </div>
    </div>
    <div class="footer-row copyright">{{ $t("common.footer.copyright") }}</div>
  </div>
</template>

<script>
import logoImage from '@/assets/images/logo.svg';
import darkLogoImage from '@/assets/images/logo-dark.svg';
import config from '../config';

export default {
  name: 'Footer',
  data() {
    return {
      config,
      currentLogoImage: logoImage,
      logoImage,
      darkLogoImage,
    };
  },
};
</script>
