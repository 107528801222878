<template>
  <div class="sphere-board">
    <img
      class="main-image"
      alt="Main image"
      src="../assets/images/sphere-board.png"
    />
    <img
      class="main-image-portrait"
      alt="Main image"
      src="../assets/images/sphere-board-portrait.png"
    />
    <div class="control-button" @click="openDiscordLocation()">
      <img
        class="enter-oniverse-button"
        alt="Enter the Oniverse"
        src="../assets/images/CHECK_THE_DETAILS_SPHEREBOARD.svg"
      />
      <img
        class="discord-button"
        alt="Enter the Oniverse"
        src="../assets/images/ENTER_THE_ONIVERSE_MEDIUM.svg"
        height="50px"
        width="auto"
      />
    </div>
  </div>
</template>

<script>
import config from '../config';

export default {
  name: 'SphereBoardView',
  data() {
    return {
      config,
    };
  },
  methods: {
    openDiscordLocation() {
      document.location.href = this.config.medium;
    },
  },
};
</script>
