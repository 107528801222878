// This file contains the data for the gallery of the team on the page "Team"
export default [
  {
    name: 'Yixi C.',
    position: 'Producer',
    description: 'Founder of <a href="https://www.c2h4losangeles.com">C2H4</a> & <a href="https://www.chemist-creations.com">Chemist Creations</a>',
    hoverDescription: 'Mean look but <strong>nice girl</strong>. Juggling with too <strong>many projects.</strong>',
    imgPath: require('../assets/images/team/team-member-4.png'),
    hoverImgPath: require('../assets/images/team/006.jpg'),
    link: 'https://www.instagram.com/yixi_q/?next=%2F&hl=fr',
  },
  {
    name: 'Cherif K.',
    position: 'Creative Director',
    description: 'Former Character Designer <a href="https://www.netflix.com/fr">Netflix</a> & <a href="https://www.disney.fr">Disney</a>',
    imgPath: require('../assets/images/team/team-member-2.png'),
    hoverImgPath: require('../assets/images/team/005.jpg'),
    hoverDescription: 'Overthinking <strong>everything</strong> everytime. <strong>Collects</strong> too much <strong>figures</strong>.',
    link: 'https://www.linkedin.com/in/kslimane/',
  },
  {
    name: 'Gwen C.',
    position: 'Tech Director',
    description: 'Former <a href="https://www.ubisoft.com/fr-fr">Ubisoft</a> & <a href="https://www.netflix.com/fr">Netflix</a>',
    imgPath: require('../assets/images/team/team-member-7.png'),
    hoverImgPath: require('../assets/images/team/004.jpg'),
    hoverDescription: '<strong>Video Game</strong> Aficinionados. Anime and <strong>Chicken addict</strong>',
    link: 'https://www.linkedin.com/in/gwenael-cogan-27695a80/',
  },
  {
    name: 'Terry B.',
    position: 'CGI Artist',
    description: 'Former <a href="https://www.illuminationmacguff.com/?lang=fr">MacGuff Illumination</a>',
    imgPath: require('../assets/images/team/team-member-6.png'),
    hoverImgPath: require('../assets/images/team/003.jpg'),
    hoverDescription: 'Pushing <strong>Blender</strong> to its limits. <strong>Double dutch</strong> wizard',
    link: 'https://www.linkedin.com/in/terry-bonvard-0b90a053/',
  },
  {
    name: 'Chams C.',
    position: 'Background Artist',
    description: '<a href="https://www.netflix.com/fr">Netflix</a> / <a href="https://www.riotgames.com/fr">Riot (Arcane)</a>',
    imgPath: require('../assets/images/team/yixi.png'),
    hoverImgPath: require('../assets/images/team/002.jpg'),
    hoverDescription: 'Creatively <strong>unstable</strong>. Should always be <strong>censored</strong>.',
    link: 'https://www.linkedin.com/in/chamschitou/',
  },
  {
    name: 'Max N.',
    position: 'Digital Marketing',
    description: 'Digital Marketing <a href="https://www.nike.com/fr">Nike</a>',
    imgPath: require('../assets/images/team/team-member-5.png'),
    hoverImgPath: require('../assets/images/team/001.jpg'),
    hoverDescription: 'Walking Web3 <strong>Encyclopedia</strong>. Great energy with <strong>bad dancing skills</strong>.',
    link: 'https://www.linkedin.com/in/hamaxnguyen/',
  },
  {
    name: 'O-Mega™',
    position: 'AI Bot.',
    description: 'Built by Professor Satoshi for Onisekai™',
    imgPath: require('../assets/images/team/OMEGA1.png'),
    hoverImgPath: require('../assets/images/team/OMEGA2.jpg'),
    hoverDescription: 'I\'m coded to <strong>like you</strong>. But damn I <strong>hate you</strong> already...',
    link: 'https://twitter.com/O_MegaBot',
  },
];
