<template>
  <div class="oniverse">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-6 oniverse-column">
        <img
          class="main-image"
          alt="Oniverse"
          src="../assets/images/oniverse.png"
        />
      </div>
      <div class="col-12 col-md-8 col-lg-6 info-column ps-md-5 oniverse-column">
        <h1 @click="openDiscordLocation()" class="enter-oniverse-button">
          <img
            class="enter-oniverse-button"
            alt="Enter the Oniverse"
            src="../assets/images/enter-oniverse.svg"
          />
          <img
            class="discord-button"
            alt="Enter the Oniverse"
            src="../assets/images/ENTER_THE_ONIVERSE_MEDIUM.svg"
            height="50px"
            width="auto"
          />
        </h1>
        <p v-html="$t('oniverse.sections.introduction')"></p>
        <p v-html="$t('oniverse.sections.details')"></p>
        <p v-html="$t('oniverse.sections.question')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../config';

export default {
  name: 'OniverseView',
  data() {
    return {
      config,
    };
  },
  methods: {
    openDiscordLocation() {
      document.location.href = this.config.medium;
    },
  },
};
</script>
