export default {
  appName: 'Onisekai',
  appKey: 'onisekai',
  appLogo: '',
  primaryColor: '#0077b6',
  env: process.env.VUE_APP_ENV || 'development', // production / test
  defaultLocale: 'en',
  defaultTitle: 'Onisekai',
  discordUrl: '',
  twitterUrl: 'https://twitter.com/Onisekai_world',
  instagramUrl: '',
  medium: 'https://medium.com/@Onisekai/onisekai-bd9656775459',
};
