import character1Image from '@/assets/images/character-mobile_0.png';
import character2Image from '@/assets/images/character-mobile_1.png';
import character3Image from '@/assets/images/character-mobile_2.png';
import character4Image from '@/assets/images/character-mobile_3.png';
import character5Image from '@/assets/images/character-mobile_4.png';
import character6Image from '@/assets/images/character-mobile_6.png';
import character7Image from '@/assets/images/character-mobile_7.png';
import character8Image from '@/assets/images/character-mobile_8.png';
import character9Image from '@/assets/images/character-mobile_9.png';

// This file contains the data for the character images that are displayed on Home
// page on mobile devices
export default [
  {
    characterImage: character1Image,
  },
  {
    characterImage: character2Image,
  },
  {
    characterImage: character3Image,
  },
  {
    characterImage: character4Image,
  },
  {
    characterImage: character5Image,
  },
  {
    characterImage: character6Image,
  },
  {
    characterImage: character7Image,
  },
  {
    characterImage: character8Image,
  },
  {
    characterImage: character9Image,
  },
];
