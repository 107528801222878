import gweckoImage from '@/assets/images/characters/Gwecko.png';
import gweckoSVG from '@/assets/images/characters/Gwecko-01.svg';
import ririImage from '@/assets/images/characters/Riri.png';
import ririSVG from '@/assets/images/characters/Riri-01.svg';
import isamuImage from '@/assets/images/characters/Isamu.png';
import isamuSVG from '@/assets/images/characters/Isamu-01.svg';
import omegaImage from '@/assets/images/characters/Omega.png';
import omegaSVG from '@/assets/images/characters/Omega-01.svg';
import soraImage from '@/assets/images/characters/Sora.png';
import usuiImage from '@/assets/images/characters/Usui.png';

// This file contains the data for the selectable character images that are displayed on Home page
// height, coords.top and coords.left are a pourcentage of the canvas.
// Characters without bubble: {} won't be clickable.
// Characters without clickableAreaImage will be grayed out.
export default [
  {
    name: 'Gwecko',
    characterImage: gweckoImage,
    clickableAreaImage: gweckoSVG,
    zIndex: 6,
    coords: {
      top: -9,
      left: 25,
    },
    height: 140,
    bubble: {
      content: [''],
      position: 'left',
      coords: {
        top: 15,
        left: 37,
      },
      size: 150,
    },
  },
  {
    name: 'Riri',
    characterImage: ririImage,
    clickableAreaImage: ririSVG,
    zIndex: 5,
    coords: {
      top: 2,
      left: 5,
    },
    height: 110,
    bubble: {
      content: [''],
      position: 'right',
      coords: {
        top: 15,
        left: 28,
      },
      size: 150,
    },
  },
  {
    name: 'Isamu',
    characterImage: isamuImage,
    clickableAreaImage: isamuSVG,
    zIndex: 5,
    coords: {
      top: 5,
      left: 74,
    },
    height: 108,
    bubble: {
      content: [''],
      position: 'left',
      coords: {
        top: 15,
        left: 75,
      },
      size: 150,
    },
  },
  {
    name: 'Usui',
    characterImage: usuiImage,
    zIndex: 2,
    coords: {
      top: 5,
      left: 56,
    },
    height: 115,
  },
  {
    name: 'Sora',
    characterImage: soraImage,
    zIndex: 2,
    coords: {
      top: 7,
      left: 22,
    },
    height: 108,
  },
  {
    name: 'Omega',
    characterImage: omegaImage,
    clickableAreaImage: omegaSVG,
    zIndex: 7,
    coords: {
      top: 64,
      left: 62,
    },
    height: 22,
    bubble: {
      content: [''],
      position: 'right',
      coords: {
        top: 47,
        left: 54,
      },
      size: 120,
    },
  },
];
