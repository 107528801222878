<template>
  <nav class="navbar">
    <div class="top-elements-wrapper">
      <div class="logo">
        <img
        :src="logoImage"
        class="logo"
        alt="Logo"
        @click="openHomePage"
        />
        <img
          :src="darkLogoImage"
          class="logo logodark"
          alt="Logo"
          @click="openHomePage"
        />
      </div>
      <SocialMenu :items="socialItems" />
    </div>
    <div class="info-wrapper">
      <div class="nav-menu">
        <router-link to="/home">{{ $t("common.nav.home") }}</router-link>
        <router-link to="/oniverse" class="oniverse-link">
          <img :src="oniverseImage" alt="Oniverse" />
        </router-link>
        <router-link to="/sphere-board">{{
          $t("common.nav.sphere-board")
        }}</router-link>
        <router-link to="/team">{{ $t("common.nav.team") }}</router-link>
        <a href="https://onisekai-mint.io/" class="mint-link" >
          <img :src="mintImage" alt="Mint" />
        </a>
      </div>
      <SocialMenu :items="socialItems" />
      <div class="extra-link-wrapper">
        <div class="shop-label">
          <div class="main-text">{{ $t("common.labels.shop") }}</div>
          <img :src="comingSoonImage" class="coming-soon" alt="Coming soon" />
        </div>
      </div>
    </div>
    <sidebar-menu
      v-bind="sidebarProps"
      v-model:collapsed="isMenuCollapsed"
      @update:collapsed="onToggleCollapse"
      @item-click="onSidebarItemClick"
    >
      <template v-slot:header>
        <div class="sidebar-header">
          <img
            :src="closeIcon"
            class="close-icon"
            alt="Close menu icon"
            @click="toggleMenu"
          />
          <img
            :src="currentLogoImage"
            class="logo"
            alt="Logo"
            @mouseover="currentLogoImage = darkLogoImage"
            @mouseout="currentLogoImage = logoImage"
            @focusin="currentLogoImage = darkLogoImage"
            @focusout="currentLogoImage = logoImage"
            @click="openHomePage"
          />
        </div>
      </template>
      <template v-slot:toggle-icon>
        <img :src="toggleMenuIcon" class="toggle-icon" alt="Toggle menu icon" />
      </template>
      <template v-slot:footer>
        <div class="sidebar-footer">
          <SocialMenu :items="socialItems" />
          <div class="footer-label">
            {{ $t("common.footer.copyright") }}
          </div>
        </div>
      </template>
    </sidebar-menu>
  </nav>
</template>

<script>
import SocialMenu from '@/components/SocialMenu.vue';
import closeIcon from '@/assets/images/close-icon.svg';
import toggleMenuIcon from '@/assets/images/toggle.svg';
import logoImage from '@/assets/images/logo.svg';
import darkLogoImage from '@/assets/images/logo-dark.svg';
import oniverseImage from '@/assets/images/oniverse-button-naked.svg';
import mintImage from '@/assets/images/mint.svg';
import twitterImage from '@/assets/images/twitter.svg';
import openseaImage from '@/assets/images/opensea.svg';
import darkTwitterImage from '@/assets/images/twitter-dark.svg';
import darkOpenseaImage from '@/assets/images/opensea-dark.svg';
import comingSoonImage from '@/assets/images/coming-soon.svg';
import config from '../config';

export default {
  name: 'NavBar',
  components: {
    SocialMenu,
  },
  data() {
    return {
      config,
      currentLogoImage: logoImage,
      logoImage,
      darkLogoImage,
      oniverseImage,
      mintImage,
      comingSoonImage,
      closeIcon,
      toggleMenuIcon,
      isMenuCollapsed: true,
      sidebarProps: {
        width: '350px',
        widthCollapsed: '0px',
        disableHover: true,
        menu: [
          {
            href: '/home',
            title: this.$t('common.nav.home'),
          },
          {
            href: '/oniverse',
            title: this.$t('common.nav.oniverse'),
            class: 'oniverse-menu-item',
          },
          {
            href: '/sphere-board',
            title: this.$t('common.nav.sphere-board'),
          },
          {
            href: '/team',
            title: this.$t('common.nav.team'),
          },
          /*
          {
            href: '/mint',
            title: this.$t('common.nav.mint'),
          },
          */
          {
            header: this.$t('common.labels.gallery'),
          },
          {
            header: this.$t('common.labels.shop'),
          },
        ],
      },
      socialItems: [
        {
          icon: twitterImage,
          darkIcon: darkTwitterImage,
          title: 'Twitter',
          url: config.twitterUrl,
        },
        {
          icon: openseaImage,
          darkIcon: darkOpenseaImage,
          title: 'Open Sea',
          disabled: true,
        },
      ],
    };
  },
  methods: {
    openHomePage() {
      this.$router.push('/home');
      this.isMenuCollapsed = true;

      setTimeout(() => {
        this.currentLogoImage = logoImage;
      }, 100);
    },
    toggleMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    onToggleCollapse(collapsed) {
      this.isMenuCollapsed = collapsed;
    },
    onSidebarItemClick() {
      this.isMenuCollapsed = true;
    },
  },
};
</script>
