import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import Popper from 'vue3-popper';
import VueSidebarMenu from 'vue-sidebar-menu';
import FloatingVue from 'floating-vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';

import App from './App.vue';
import config from './config';
import localeEn from './config/locales/en.json';
import './registerServiceWorker';
import router from './router';
import store from './store';

// Import Theme scss
import './assets/scss/main.scss';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import 'floating-vue/dist/style.css';

const isProduction = process.env.NODE_ENV === 'production';

// Global variables
window.myApp = {
  locale: config.defaultLocale || 'en',
};

const initialLocale = store.state.currentLocale && store.state.currentLocale !== 'null' ? store.state.currentLocale : config.defaultLocale;

// Initialize i18n module
const i18n = createI18n({
  locale: initialLocale, // Set locale
  messages: {
    en: localeEn,
  },
  silentTranslationWarn: process.env.VUE_APP_NO_I18N_LOGS || isProduction,
});

function init() {
  // Retrieve the locale from the local storage
  let locale = config.defaultLocale;
  try {
    locale = localStorage.getItem(`${config.appKey}_locale`);
  } catch (err) {
    locale = config.defaultLocale;
  }

  store.commit(
    'currentLocale',
    locale,
  );

  // Create Vue application and register necessary modules
  createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueSidebarMenu)
    .use(VueSvgInlinePlugin, {
      attributes: {
        data: ['src'],
        remove: ['alt'],
      },
    })
    .use(FloatingVue)
    .component(VueCountdown.name, VueCountdown)
    .component('Popper', Popper)
    .mount('#app');
}

init();
