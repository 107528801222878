<template>
  <NavBar />
  <div>
    <router-view />
  </div>
  <Footer />
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResized);
    this.onWindowResized();
  },
  methods: {
    onWindowResized: () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    },
  },
};
</script>
